// $.ajaxSetup({
//    headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
// });


$(document).ready(function(){

	toastr.options = {
	  "closeButton": false,
	  "debug": false,
	  "newestOnTop": false,
	  "progressBar": true,
	  "positionClass": "toast-top-right",
	  "preventDuplicates": false,
	  "onclick": null,
	  "showDuration": "300",
	  "hideDuration": "1000",
	  "timeOut": "5000",
	  "extendedTimeOut": "1000",
	  "showEasing": "swing",
	  "hideEasing": "linear",
	  "showMethod": "fadeIn",
	  "hideMethod": "fadeOut"
	}

	$('.send-btn').click(function(){            
			var form = $("#newsletterForm");

		    $.ajax({
		        url     : form.attr("action"),
		        type    : form.attr("method"),
		        data    : form.serialize(),
		        dataType: "json",
		        success : function ( json ) 
		        {
		            toastr.success( json.message , "Va multumim pentru abonarea la newsletter!" );
		        },
		        error   : function ( jqXhr, json, errorThrown ) 
		        {
		            var errors = jqXhr.responseJSON;
		            var errorsHtml= '';
		            $.each( errors, function( key, value ) {
		                errorsHtml += '<div class="nsErrorMessages">' + value[0] + '</div>'; 
		            });
		            toastr.error( errorsHtml , "Eroare!");
		        }
		    })
		    .done(function(response)
		    {
		        //
		    })
		    .fail(function( jqXHR, json ) 
		    {
		        //
		    });
		    return false;    
	});  

		tinymce.init({
		    selector: ".editor",
		    menubar: false,
		    plugins: [
		        // "advlist autolink lists link image charmap print preview anchor",
		        // "searchreplace visualblocks code fullscreen",
		        // "insertdatetime media table contextmenu paste"
		    ],
		    toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image"
		});


		var errorDiv = $('.alert-danger').first();
			if(errorDiv.offset()){
			var scrollPos = errorDiv.offset().top;
			$(window).scrollTop(scrollPos);
		}


});


